<template>
    <!-- ============================ Hero Banner  Start================================== -->
    <div class="hero-banner full jumbo-banner" style="background:#f4f9fd url(assets/img/bg2.png);">
        <div class="container">
            <div class="row align-items-center">

                <div class="col-lg-7 col-md-8">
                    <a href="#" class="header-promo light w-inline-block">
                    </a>
                    <h1>Build up your ogranization using Sabenzi's <span class="text-info">Talent Management System.</span></h1>
                </div>

                <div class="col-lg-5 col-md-4">
                    <base-image
                        imgName="edu_2.png"
                        imgGeo="home"
                        imgClass="img-fluid"
                        imgAlt="Hero"
                     >
                     </base-image>
                </div>

            </div>
        </div>
    </div>
    <!-- ============================ Hero Banner End ================================== -->

    <!-- ============================ How It Work Start ==================================== -->
    <section class="how-it-works">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-9">
                    <div class="sec-heading">
                        <h2>How It <span class="theme-cl-2">Works?</span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 col-sm-4">
                    <div class="working-process"><span class="process-img"><img src="https://via.placeholder.com/100x100" class="img-responsive" alt=""><span class="process-num">01</span></span>
                        <h4>Create An Account</h4>
                        <p>Post a job to tell us about your project. We'll quickly match you with the right freelancers find place best.</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <div class="working-process"><span class="process-img"><img src="https://via.placeholder.com/100x100" class="img-responsive" alt=""><span class="process-num">02</span></span>
                        <h4>Access to TMS</h4>
                        <p>Post a job to tell us about your project. We'll quickly match you with the right freelancers find place best.</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <div class="working-process"><span class="process-img"><img src="https://via.placeholder.com/100x100" class="img-responsive" alt=""><span class="process-num">03</span></span>
                        <h4>Top quality candidates</h4>
                        <p>Post a job to tell us about your project. We'll quickly match you with the right freelancers find place best.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ============================ How It Work End ==================================== -->

    <!-- ============================ Newest Designs Start ==================================== -->
    <section class="gray-bg">
        <div class="container">

            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-9">
                    <div class="sec-heading">
                        <h2>Amazing features in our <span class="theme-cl-2">TMS</span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">

                <!-- Single category -->
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="small-category-2">

                        <div class="small-category-2-thumb themes-light">
                            <a href="search.html"><i class="bi bi-megaphone"></i></a>
                        </div>
                        <div class="sc-2-detail">
                            <h5 class="sc-jb-title"><a href="search.html">Job Ads</a></h5>
                            <span><u>learn more</u></span>
                        </div>

                    </div>
                </div>

                <!-- Single category -->
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="small-category-2">

                        <div class="small-category-2-thumb themes-light">
                            <a href="search.html"><i class="bi bi-people-fill"></i></a>
                        </div>
                        <div class="sc-2-detail">
                            <h5 class="sc-jb-title"><a href="search.html">Teams Manager</a></h5>
                            <span><u>learn more</u></span>
                        </div>

                    </div>
                </div>

                <!-- Single category -->
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="small-category-2">

                        <div class="small-category-2-thumb themes-light">
                            <a href="search.html"><i class="bi bi-list-check"></i></a>
                        </div>
                        <div class="sc-2-detail">
                            <h5 class="sc-jb-title"><a href="search.html">Task manager</a></h5>
                            <span><u>learn more</u></span>
                        </div>

                    </div>
                </div>

                <!-- Single category -->
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="small-category-2">

                        <div class="small-category-2-thumb themes-light">
                            <a href="search.html"><i class="bi bi-calendar-check"></i></a>
                        </div>
                        <div class="sc-2-detail">
                            <h5 class="sc-jb-title"><a href="search.html">Calendar Schedular</a></h5>
                            <span><u>learn more</u></span>
                        </div>

                    </div>
                </div>

                <!-- Single category -->
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="small-category-2">

                        <div class="small-category-2-thumb themes-light">
                            <a href="search.html"><i class="bi bi-recycle"></i></a>
                        </div>
                        <div class="sc-2-detail">
                            <h5 class="sc-jb-title"><a href="search.html">Reusable components</a></h5>
                            <span><u>learn more</u></span>
                        </div>

                    </div>
                </div>

                <!-- Single category -->
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="small-category-2">

                        <div class="small-category-2-thumb themes-light">
                            <a href="search.html"><i class="bi bi-shield-lock"></i></a>
                        </div>
                        <div class="sc-2-detail">
                            <h5 class="sc-jb-title"><a href="search.html">Role Based Permissions</a></h5>
                            <span><u>learn more</u></span>
                        </div>

                    </div>
                </div>

                <!-- Single category -->
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="small-category-2">

                        <div class="small-category-2-thumb themes-light">
                            <a href="search.html"><i class="bi bi-ui-checks-grid"></i></a>
                        </div>
                        <div class="sc-2-detail">
                            <h5 class="sc-jb-title"><a href="search.html">Custom accessments</a></h5>
                            <span><u>learn more</u></span>
                        </div>

                    </div>
                </div>

                <!-- Single category -->
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="small-category-2">

                        <div class="small-category-2-thumb themes-light">
                            <a href="search.html"><i class="bi bi-ui-checks"></i></a>
                        </div>
                        <div class="sc-2-detail">
                            <h5 class="sc-jb-title"><a href="search.html">Evaluation Manager</a></h5>
                            <span><u>learn more</u></span>
                        </div>

                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mt-3 text-center">
                        <a href="#" class="_browse_more-light light">Get Started</a>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!-- ============================ Newest Designs End ==================================== -->

    <!-- ============================ Featured Themes Start ==================================== -->
    <section class="light-w">
        <div class="container">

            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-9">
                    <div class="sec-heading">
                        <h2>What People <span class="theme-cl-2">Saying</span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>

            <div class="row">

                <!-- Single blog Grid -->
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="blog-wrap-grid">

                        <div class="blog-thumb">
                            <a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
                        </div>

                        <div class="blog-info">
                            <span class="post-date">By Shilpa Sheri</span>
                        </div>

                        <div class="blog-body">
                            <h4 class="bl-title"><a href="blog-detail.html">Why people choose listio for own properties</a></h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p>
                        </div>


                    </div>
                </div>

                <!-- Single blog Grid -->
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="blog-wrap-grid">

                        <div class="blog-thumb">
                            <a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
                        </div>

                        <div class="blog-info">
                            <span class="post-date">By Shaurya</span>
                        </div>

                        <div class="blog-body">
                            <h4 class="bl-title"><a href="blog-detail.html">List of benifits and impressive listeo services</a></h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p>
                        </div>


                    </div>
                </div>

                <!-- Single blog Grid -->
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="blog-wrap-grid">

                        <div class="blog-thumb">
                            <a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
                        </div>

                        <div class="blog-info">
                            <span class="post-date">By Admin K.</span>
                        </div>

                        <div class="blog-body">
                            <h4 class="bl-title"><a href="blog-detail.html">What people says about listio properties</a></h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p>
                        </div>


                    </div>
                </div>

            </div>

        </div>
    </section>
    <!-- ============================ Featured Themes End ==================================== -->

    <!-- ============================ News Updates Start ==================================== -->
    <section class="min-sec">
        <div class="container">

            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-9">
                    <div class="sec-heading">
                        <h2>Great Price <span class="theme-cl-2">For You</span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- Single Pricing Box -->
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="pricing_boxes">
                        <div class="pricing_boxes_header">
                            <div class="pricing_thumb_title">
                                <h4>Basic</h4>
                                <p>For organising every corner of your life</p>
                            </div>
                        </div>
                        <div class="pricing_boxes_middle">
                            <h2 class="pricing_rate">$29</h2>
                            <span class="time_esti">per month</span>
                            <a href="#" class="btn pricing_btn">Get Started</a>
                        </div>
                        <div class="pricing_boxes_detail">
                            <ul class="pricing_lists">
                                <li>Access Whole Items</li>
                                <li>Sync across device</li>
                                <li>Share with 5 guests</li>
                                <li>24x7 Email Support</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- Single Pricing Box -->
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="pricing_boxes featured">
                        <div class="pricing_boxes_header">
                            <div class="pricing_thumb_title">
                                <h4>Professional</h4>
                                <p>For organising every corner of your life</p>
                            </div>
                        </div>
                        <div class="pricing_boxes_middle">
                            <h2 class="pricing_rate">$99</h2>
                            <span class="time_esti">Per 1 Year</span>
                            <a href="#" class="btn pricing_btn">Get Started</a>
                        </div>
                        <div class="pricing_boxes_detail">
                            <ul class="pricing_lists">
                                <li>Access Whole Items</li>
                                <li>Sync across device</li>
                                <li>Share with 10 guests</li>
                                <li>24x7 Email Support</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- Single Pricing Box -->
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="pricing_boxes">
                        <div class="pricing_boxes_header">
                            <div class="pricing_thumb_title">
                                <h4>Organisation</h4>
                                <p>For organising every corner of your life</p>
                            </div>
                        </div>
                        <div class="pricing_boxes_middle">
                            <h2 class="pricing_rate">$199</h2>
                            <span class="time_esti">Lifetime</span>
                            <a href="#" class="btn pricing_btn">Get Started</a>
                        </div>
                        <div class="pricing_boxes_detail">
                            <ul class="pricing_lists">
                                <li>Access Whole Items</li>
                                <li>Sync across device</li>
                                <li>Share with 5 guests</li>
                                <li>24x7 Email Support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mt-3 text-center">
                        <a href="#" class="_browse_more-light light">Start your adventure</a>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!-- ============================ News Updates End ==================================== -->
</template>

<script>
// @ is an alias to /src

export default {
  name: "ForOrganizations",
};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

</style>
